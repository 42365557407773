@mixin selectInput {
  background-color: transparent !important;
  padding: 8px !important;
  margin-right: 10px !important;
  border-radius: 10px;
  border: none !important;
  display: flex;
  align-items: center;
}

@mixin topRightSelector {
  cursor: pointer !important;

  .MuiInputBase-input {
    @include selectInput();
  }

  svg {
    font-size: 1.25rem !important;
  }

  .viewIcon {
    color: #7a7a7a;
    font-size: 14px !important;
  }
}

.from-texts {
  resize: none;
  padding: 10px 20px;
  font-size: 13px;
  outline: none;
  border-radius: 10px;
}

.butn {
  background: #f4f5f7;
  border-radius: 10px;
  border: 1px solid;
  padding: 12px 16px;
  margin-top: 11px;
  position: absolute;
}

.userImages {
  height: 24px;
  width: 24px;
  border-radius: 6px;
  // border: 1px solid #979797;
}

.selectedFile {
  margin: 0px 30px 0px 0px;

  .selectedImage {
    height: 115px;
    width: 116px;
    border-radius: 10px 10px 0px 0px;
    margin-left: 21px;
  }

  .delFile {
    position: relative;
    top: -45px;
    right: 25px;
    font-size: 10px;
    background: #eee;
    padding: 3px 5px;
    border-radius: 50px;
    cursor: pointer;
  }
}

.labels {
  background: rgb(255, 247, 230);
  color: #ffab00;
  border-radius: 4px;
  padding: 4px;
  font-size: 10px;
  margin: 0px 10px 0px 0px;
}

.userImageRounded {
  height: 22px;
  width: 22px;
  border-radius: 50px;
  margin-right: -10px;
}

.userUnAssigned {
  height: 22px;
  width: 12px;
  border-radius: 50px;
  margin-right: 0px;
}

.null_img {
  background-color: #455153;
  color: #fff;
  font-size: 12px;
  padding: 3px 5px;
}

.frams {
  padding-left: 8%;
}

.btun {
  margin-top: 9px;
}

.tr_th {
  background: #f4f5f7;
  border-radius: 10px 0px 0px 10px;
}

.tr_th1 {
  background: #f4f5f7;
  border-radius: 0px 10px 10px 0px;
}

.drop-togle {
  cursor: pointer;
}

.bnts {
  width: 95px;
}

.main {
  input {
    margin-top: -15px;
  }
}

.select_width {
  width: 96%;
  background-color: #fff;
  position: absolute;
  margin-top: -2px;
  border: 1px solid #eee;
  border-radius: 6px;
  margin-top: -10px;
  position: absolute;
  background: #fff;
  z-index: 1;

  .userImages {
    height: 20px;
    width: 20px;
    border-radius: 6px;
  }

  li {
    display: flex;
    align-items: center;
    list-style: none;
    padding: 10px 20px;
    margin-left: -33px;
    position: relative;
    cursor: pointer;

    &:hover {
      background: #e5edfa;
    }

    .checkbx {
      position: absolute;
      right: 5%;
    }
  }
}

.select_width1 {
  width: 46.5%;
  background-color: #fff;
  position: absolute;
  margin-top: -2px;
  border: 1px solid #eee;
  border-radius: 6px;
  margin-top: -10px;
  position: absolute;
  background: #fff;
  z-index: 1;

  .userImages {
    height: 20px;
    width: 20px;
    border-radius: 6px;
  }

  li {
    display: flex;
    align-items: center;
    list-style: none;
    padding: 10px 20px;
    margin-left: -33px;
    position: relative;
    cursor: pointer;

    &:hover {
      background: #e5edfa;
    }

    .checkbx {
      position: absolute;
      right: 5%;
    }
  }
}

.select-options {
  margin-top: -2px;
  border: 1px solid #eee;
  border-radius: 6px;
  margin-top: -10px;
  position: absolute;
  background: #fff;
  z-index: 1;

  li {
    display: flex;
    align-items: center;
    list-style: none;
    padding: 10px 20px;
    margin-left: -33px;
    position: relative;
    cursor: pointer;

    &:hover {
      background: #e5edfa;
    }

    .checkbx {
      position: absolute;
      right: 5%;
    }
  }
}

.selected-images-row {
  display: flex;
}

.selected-image {
  margin-right: -10px;
  height: 22px;

  img {
    height: 25px;
    width: 25px;
    border-radius: 6px;
    margin-bottom: 5px;
  }
}

.react-datepicker {
  font-family: "Your Preferred Font", sans-serif;
}

.custom-datepicker input {
  border: none !important;
  outline: none !important;
  padding: 0px 2px;
}

.text-red .react-datepicker__input-container input {
  &::placeholder {
    color: red;
  }
}

// .border__color {
//   border: 1px solid #707070 !important;
// }

// input::placeholder {
//   color: #7b858c !important;
// }

.react-datepicker-wrapper {
  .react-datepicker__input-container {
    display: block;
    padding: 12px;
    outline: none;

    input {
      outline: none;
      width: inherit;
      background: transparent;
      border: none !important;
      cursor: pointer;
    }
  }
}

.custom-tags-input {
  border: 1px solid #ccc;
  padding: 2px;
  font-size: 14px;

  input {
    margin-top: 5px;
    padding: 5px 14px;
    width: 120px;
  }
}

.drops {
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  margin-left: -141px;

  .privacy {
    color: #0052cc !important;
    font-size: 8px !important;
    cursor: pointer;

    &:hover {
      background: none;
    }
  }
}

.w-100 {
  width: 100% !important;
}

.form-control {
  &.input-group {
    background: white !important;
    border: 1px solid gray !important;
    border-radius: 6px !important;
    padding: 6px 12px !important;
    width: 100%;
    font-size: 14 !important;
    margin-top: 7px !important;
    //   font-family: var(--font-family)  !important;
    color: gray;
    font-weight: 400 !important;

    //   font-family:  !important;
    color: gray;
    font-weight: 400px !important;

    &.modal-border1 {
      border: 1px solid gray !important;
      border-radius: 8px !important;
      padding: 0px 0px !important;
    }

    &.modal-border {
      border: 0px solid gray !important;
      max-height: 200px !important;
      max-width: 643px !important;
    }

    &.cover-letter {
      border: 0px solid gray !important;
    }

    // .MuiInputBase-root {
    //   .MuiInputBase-input {
    //     padding: 0px !important;
    //     background-color: transparent !important;
    //   }
    // }
    .MuiInputBase-root {
      .MuiInputBase-input {
        padding: 4px !important;
        color: gray !important;
        font-size: 14px !important;
        font-weight: 14px !important;
        //background-color: transparent !important;
      }
    }
  }
}

.inputcontrasts {
  border: none !important;
  border-radius: 10px !important;
  font-size: 14px !important;
  // font-family: "Poppins", Regular !important;
  padding: 2px 10px 5px !important;
  outline: none !important;

  // color: #9b9d9e !important;
  &:focus,
  &:focus-visible {
    box-shadow: none !important;
    outline: none !important;
    border: none !important;
  }
}

.input-contrasts-invalid {
  border: 1px solid #b42318 !important;
}

.inputSelect {
  padding: 2px 10px !important;
}

.mui-selector-color {
  color: #525253 !important;
}

.task-labels {
  input {
    padding: 12px 12px 12px 12px !important;
  }
}

.MuiOutlinedInput-input {
  &.MuiInputBase-input {
    // background-color: transparent !important;
    padding: 12px 12px 12px 4px !important;
    margin-right: 10px !important;
    border-radius: 10px;
    border: none !important;
    // font-family: "Poppins", Regular;
    display: flex;
    align-items: center;
  }
}

.MuiAutocomplete-input {
  cursor: pointer;
}

.public-DraftEditorPlaceholder-root {
  color: #707070 !important;
}

.MuiOutlinedInput-input.MuiInputBase-input::placeholder {
  color: #707070;
  opacity: 1;
}

.task-follower {
  .MuiOutlinedInput-input {
    &.MuiInputBase-input {
      padding: 12px 12px 12px 4px !important;
    }
  }
}

.attachment {
  .btn-label {
    color: #0052cc;
    width: fit-content;
  }

  .add-icon-blue {
    width: 14px;
    height: 14px;
    background-image: url("../images/AddIconBlue.svg");
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
    margin-right: 10px;
  }
}

.file-input-label {
  display: flex;
  width: 100%;
  padding: 14px 10px 14px 59px;
  align-items: center;
  background-color: #fff;
  border: 1px solid #707070;
  border-radius: 10px;
  cursor: pointer;
  font-size: 14px;
}

.input-file {
  position: absolute;
  left: 0;
  /* top: 15px; */
  height: 100%;
  // width: 44px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #707070;
  border-radius: 10px;
  background: #f4f5f7;
}

.mt-18 {
  margin-top: 18px !important;
}

.mt-34 {
  margin-top: 34px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.breadcrumb_link {
  font-size: 14px;
  line-height: 16px;
  margin: 0 0 11px;

  a {
    text-decoration: none;
    color: #707070;
  }
}

.subheader_btn {
  min-width: 122px !important;
  border: 1px solid #9b9d9e !important;
  outline: none !important;

  .MuiOutlinedInput-notchedOutline {
    border: none !important;
    border-color: none !important;
  }

  @include topRightSelector();
}

.subheader_filter_btn {
  border: 1px solid #9b9d9e !important;
  outline: none !important;

  .MuiOutlinedInput-notchedOutline {
    border: none !important;
    border-color: none !important;
  }

  @include topRightSelector();
}

.sort_by_drop {
  margin-top: 11px;
  padding: 2px 10px !important;
  border: 1px solid #707070 !important;
  outline: none !important;

  &:hover {
    outline: none !important;
  }

  svg {
    font-size: 1.25rem !important;
  }

  .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }
}

.task_modal_icon {
  height: 24px !important;
  width: 24px !important;
}

// .drop_edit_icon {
//     position: relative;
//     width: 125px !important;
// }

// .drop_icon {
//     position: absolute;
//     left: 120px !important;
//     top: 5px !important;
// }

.avatar__border {
  border-radius: 6px;
  border: 1px solid #979797 !important;
}

.border__color {
  border: 1px solid #707070 !important;
}

.css-10hburv-MuiTypography-root {
  font-size: 14px !important;
  // font-family: "Poppins", Regular !important;
}

.execution-report-modal {
  .execution-report-header {
    padding: 30px;
    border-radius: 10px;
    background-color: #F1F1F3;
    display: flex;

    .stat-col {
      width: 50%;
      display: flex;
      flex-direction: column;
      gap: 30px;

      .execution-stats {
        display: flex;
        align-items: center;

        .stat-bold-text {
          font-size: 14px;
          font-weight: 500;
        }

        .stat-normal-text {
          color: #707070;
          font-size: 12px;
        }

        .stat {
          height: 14px;
          width: 14px;
          border-radius: 50%;
        }

        .failed {
          background-color: #F0142F;
        }

        .passed {
          background-color: #28A745;
        }

        .restart {
          background-color: #FFAB00;
        }

        .blocked {
          background-color: #707070;
        }
      }

    }
  }

  .execution-report-body {
    min-height: 500px;

    .execution-chart-box {
      background-color: #F1F1F3;
      margin-top: 30px;
      padding: 30px;
      border-radius: 10px;
    }
  }
}

.defect-chart-box {
  background-color: #F1F1F3;
  margin-top: 30px;
  padding: 15px;
  border-radius: 10px;
  max-height: 150px;
}

.testCase-small-card {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1019607843) !important;
  border-radius: 10px !important;
  padding: 13px 12px;
  margin-bottom: 12px;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 12px;

  .case-index {
    min-width: 32px !important;
    text-align: center;
  }
}

.execution_text_area {
  resize: none;
  min-height: 160px !important;
}

.select-case-row {
  min-height: 400px !important;

  .add_scroll {
    max-height: 400px !important;
  }
}